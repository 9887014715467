import { useEffect, useState } from "react";
import { theme_color_black, theme_color_white } from "../../const";
import { useSelector } from "react-redux";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import sofa_image from "./../images/sofa_image.png";

export const Homepage = ({ homeRef }) => {
  const deviceType = useSelector((state) => state.DeviceType.screenType);
  const languageState = useSelector((state) => state.Language.language);
  const [isMobile, setIsMobile] = useState(deviceType === "mobile");
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    if (deviceType === "wide") {
      setIsMobile(false);
    }
    if (deviceType === "narrow") {
      setIsMobile(false);
    }
    if (deviceType === "mobile") {
      setIsMobile(true);
    }
  }, [deviceType]);

  useEffect(() => {
    if (languageState === "English") {
      import(`../en.json`)
        .then((res) => {
          setTranslations(res.home);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
    if (languageState === "Malay") {
      import(`../bm.json`)
        .then((res) => {
          setTranslations(res.home);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
  }, [languageState]);

  return (
    <Flex
      ref={homeRef}
      flexDir={"column"}
      w={"100%"}
      bgColor={theme_color_white}
      p={isMobile ? "10px" : "50px"}
      pl={"50px"}
      pr={"50px"}
      pt={isMobile ? "70px" : "0px"}
    >
      {isMobile && (
        <Flex alignContent={"start"}>
          <Image src={sofa_image} position={"top"} />
        </Flex>
      )}
      <Flex
        flexDir={isMobile ? "column" : "row"}
        w={"100%"}
        pb={"20px"}
        pt={"20px"}
      >
        <Flex
          justifyContent={"start"}
          align={isMobile ? "center" : "start"}
          w={isMobile ? "100%" : "50%"}
          p={"5px"}
        >
          <Text
            textAlign={isMobile ? "center" : "start"}
            className="font-link-Anton"
            fontSize={isMobile ? "45px" : "70px"}
          >
            {translations.tagline}
          </Text>
        </Flex>
        <Flex
          direction="column"
          w={isMobile ? "100%" : "50%"}
          p={"5px"}
          justify="space-between"
        >
          <Text
            className="font-link-OpenSans"
            fontSize={isMobile ? "20px" : "25px"}
            textAlign={"justify"}
          >
            {translations.message}
          </Text>
          <Flex
            alignSelf={isMobile ? "center" : "flex-end"}
            className="font-link-Anton"
            fontSize={isMobile ? "30px" : "44px"}
            bgColor={theme_color_black}
            color={theme_color_white}
            p={"10px"}
            pl={"5%"}
            pr={"5%"}
            mt={isMobile ? "20px" : "25px"}
            textAlign={"center"}
            borderRadius={"20px"}
            boxShadow={"7px 7px 5px rgba(0, 0, 0, 0.5)"}
            cursor={"pointer"}
            onClick={() => {
              window.open(
                "https://www.airbnb.com/h/rileklu",
                "_blank",
                "noopener,noreferrer"
              );
            }}
          >
            {translations.button}
          </Flex>
        </Flex>
      </Flex>
      {!isMobile && (
        <Flex alignContent={"start"} p={"40px"}>
          <Image src={sofa_image} position={"bottom"} />
        </Flex>
      )}
    </Flex>
  );
};
