import { useEffect, useState } from "react";
import { theme_color_black, theme_color_white } from "../../const";
import { useSelector } from "react-redux";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import map from "./../images/map.svg";
import map_small from "./../images/map_small.svg";
import location_icon from "./../images/location_icon.svg";
import time_icon from "./../images/time_icon.svg";
import line from "./../images/line.svg";

export const Location = ({ locationRef }) => {
  const deviceType = useSelector((state) => state.DeviceType.screenType);
  const languageState = useSelector((state) => state.Language.language);
  const [isMobile, setIsMobile] = useState(deviceType === "mobile");
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    if (deviceType === "wide") {
      setIsMobile(false);
    }
    if (deviceType === "narrow") {
      setIsMobile(false);
    }
    if (deviceType === "mobile") {
      setIsMobile(true);
    }
  }, [deviceType]);

  useEffect(() => {
    if (languageState === "English") {
      import(`../en.json`)
        .then((res) => {
          setTranslations(res.location);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
    if (languageState === "Malay") {
      import(`../bm.json`)
        .then((res) => {
          setTranslations(res.location);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
  }, [languageState]);

  return (
    <Flex
      ref={locationRef}
      flexDir={"column"}
      justifyContent={"space-around"}
      w={"100%"}
      bgColor={theme_color_white}
      p={isMobile ? "20px" : "50px"}
    >
      <Flex
        justifyContent={isMobile ? "center" : "start"}
        w={"100%"}
        align={isMobile ? "center" : "start"}
      >
        <Text
          textAlign={isMobile ? "center" : "start"}
          className="font-link-Anton"
          fontSize={isMobile ? "30px" : "43px"}
          color={"#AF6534"}
        >
          {translations.tagline1}
        </Text>
      </Flex>
      <Flex
        justifyContent={isMobile ? "center" : "start"}
        w={"100%"}
        align={isMobile ? "center" : "start"}
      >
        <Text
          textAlign={isMobile ? "center" : "start"}
          className="font-link-Anton"
          fontSize={isMobile ? "45px" : "60px"}
          color={theme_color_black}
        >
          {translations.tagline2}
        </Text>
      </Flex>
      <Flex h={isMobile ? "30px" : "50px"}></Flex>
      <Flex
        flexDir={isMobile ? "column" : "row"}
        w={isMobile ? "90%" : "100%"}
        justifyContent={"space-around"}
        alignSelf={"center"}
      >
        <Flex flexDir={"row"} justifyContent={isMobile ? "start" : "center"}>
          <Flex justify={"center"} pr={isMobile ? "5px" : "20px"}>
            <Image
              src={location_icon}
              align={"center"}
              width={isMobile ? "30px" : "50px"}
            />
          </Flex>
          <Flex flexDir={"column"} justifyContent={"center"}>
            <Flex justify={"start"}>
              <Text
                textAlign={"start"}
                align={"center"}
                className="font-link-Benne"
                fontSize={isMobile ? "20px" : "35px"}
                color={theme_color_black}
              >
                {translations.message1}
              </Text>
            </Flex>
            <Flex justify={"start"}>
              <Text
                textAlign={"start"}
                align={"center"}
                className="font-link-Benne"
                fontSize={isMobile ? "20px" : "35px"}
                color={theme_color_black}
              >
                {translations.message2}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        {!isMobile && (
          <Flex justify={"center"}>
            <Flex justify={"center"}>
              <Image src={line} align={"center"} />
            </Flex>
          </Flex>
        )}
        <Flex flexDir={"row"} justifyContent={isMobile ? "start" : "center"}>
          <Flex justify={"center"} pr={isMobile ? "5px" : "20px"}>
            <Image
              src={time_icon}
              align={"center"}
              width={isMobile ? "30px" : "50px"}
            />
          </Flex>
          <Flex flexDir={"column"} justifyContent={"center"}>
            <Flex justify={"start"}>
              <Text
                textAlign={"start"}
                align={"center"}
                className="font-link-Benne"
                fontSize={isMobile ? "20px" : "35px"}
                color={theme_color_black}
              >
                {translations.message3}
              </Text>
            </Flex>
            <Flex justify={"start"}>
              <Text
                textAlign={"start"}
                align={"center"}
                className="font-link-Benne"
                fontSize={isMobile ? "20px" : "35px"}
                color={theme_color_black}
              >
                {translations.message4}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex justify={"center"} pt={isMobile ? "30px" : "50px"}>
        <Image src={isMobile ? map_small : map} align={"center"} />
      </Flex>
    </Flex>
  );
};
