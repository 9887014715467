import { useEffect, useRef, useState } from "react";
import {
  placesAroundList_en,
  placesAroundList_bm,
  theme_color_black,
  theme_color_white,
} from "../../const";
import { useSelector } from "react-redux";
import { Flex, Image, Text } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper";
import useHover from "./../useHover";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

export const PlacesAround = () => {
  const deviceType = useSelector((state) => state.DeviceType.screenType);
  const languageState = useSelector((state) => state.Language.language);
  const [isMobile, setIsMobile] = useState(deviceType === "mobile");
  const [translations, setTranslations] = useState({});
  const [placesAround, setPlacesAround] = useState(placesAroundList_en);
  const [image, setImage] = useState(placesAround[0].place);
  const [description, setDescription] = useState(placesAround[0].description);
  const [activeIndex, setActiveIndex] = useState(0);
  let placesAroundLength = placesAround.length;
  const swiperRef = useRef(null);
  const [flexRef, isHovered] = useHover();

  useEffect(() => {
    if (deviceType === "wide") {
      setIsMobile(false);
    }
    if (deviceType === "narrow") {
      setIsMobile(false);
    }
    if (deviceType === "mobile") {
      setIsMobile(true);
    }
  }, [deviceType]);

  useEffect(() => {
    if (languageState === "English") {
      import(`../en.json`)
        .then((res) => {
          setTranslations(res.placesAround);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
      setPlacesAround(placesAroundList_en);
    }
    if (languageState === "Malay") {
      import(`../bm.json`)
        .then((res) => {
          setTranslations(res.placesAround);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
      setPlacesAround(placesAroundList_bm);
    }
  }, [languageState]);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      if (isHovered) {
        swiperRef.current.swiper.autoplay.stop();
      } else {
        swiperRef.current.swiper.autoplay.start();
      }
    }
  }, [isHovered]);

  const LocationBox = ({ location, activeIndex, index }) => {
    return (
      <Flex
        w={"100%"}
        color={activeIndex === index ? "#AF6534" : "black"}
        p={isMobile ? "5px" : "30px"}
        fontSize={isMobile ? "16px" : "23px"}
        bgColor="whites"
        justify={"center"}
        align={"center"}
        textAlign={"center"}
        borderTopWidth={"1px"}
        borderBottomWidth={"1px"}
        borderColor={"#AF6534"}
        margin={"0px"}
        h={"100%"}
      >
        {location}
      </Flex>
    );
  };

  return (
    <Flex
      flexDir={"column"}
      justifyContent={"space-around"}
      w={"100%"}
      bgColor={theme_color_white}
      pt={"50px"}
    >
      <Flex
        justifyContent={isMobile ? "center" : "start"}
        w={"100%"}
        align={"center"}
        pl={isMobile ? "0px" : "50px"}
      >
        <Text
          textAlign={isMobile ? "center" : "start"}
          className="font-link-Anton"
          fontSize={isMobile ? "45px" : "60px"}
          color={theme_color_black}
        >
          {translations.tagline1}
        </Text>
      </Flex>
      <Flex
        justifyContent={isMobile ? "center" : "start"}
        w={"100%"}
        align={"center"}
        pl={isMobile ? "0px" : "50px"}
      >
        <Text
          textAlign={isMobile ? "center" : "start"}
          className="font-link-Anton"
          fontSize={isMobile ? "30px" : "44px"}
          color={"#AF6534"}
        >
          {translations.tagline2}
        </Text>
      </Flex>
      <Flex
        flexDir={isMobile ? "column" : "row"}
        justifyContent={"space-around"}
        w={"100%"}
        align={"center"}
        pl={isMobile ? "0px" : "50px"}
        pr={isMobile ? "0px" : "50px"}
        pt={"50px"}
      >
        <Flex
          w={isMobile ? "60%" : "40%"}
          justifyContent={"center"}
          alignContent={"start"}
        >
          <Swiper
            ref={swiperRef}
            direction={"vertical"}
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={isMobile ? "3" : "5"}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 0,
              modifier: 0,
            }}
            autoplay={{
              delay: 2000, // 3 seconds
              disableOnInteraction: false,
            }}
            modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
            className="swiper_container"
            style={{
              alignSelf: "end",
              width: "100%",
              maxHeight: isMobile ? "150px" : "300px",
              overflowY: "auto",
              scrollbarWidth: "none",
              overflow: "hidden",
            }}
            onSlideChange={(swiper) => {
              setImage(placesAround[swiper.realIndex].image);
              setDescription(placesAround[swiper.realIndex].description);
              setActiveIndex(swiper.realIndex);
            }}
          >
            {Array.from(
              { length: placesAroundLength },
              (_, index) => index
            ).map((index) => {
              return (
                <SwiperSlide
                  key={index}
                  style={{ margin: 0, padding: 0, height: "100%" }}
                >
                  <LocationBox
                    location={placesAround[index].place}
                    activeIndex={activeIndex}
                    index={index}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Flex>
        <Flex
          w={isMobile ? "85%" : "55%"}
          h={isMobile ? "320px" : "420px"}
          position="relative"
          ref={flexRef}
        >
          <Image
            src={image}
            objectFit="cover"
            width="100%"
            height="100%"
            borderRadius={"20px"}
          />
          {isHovered && (
            <Flex
              position="absolute"
              bottom="0"
              left="0"
              width="100%"
              height="60%"
              alignItems="end"
              bgColor="rgba(0, 0, 0, 0)"
              _hover={{
                bgGradient:
                  "linear(to-b, rgba(0, 0, 0, 0) 2%, rgba(0, 0, 0, 0.3) 20%, rgba(0, 0, 0, 0.7) 70%, black 100%)",
              }}
              color="white"
              pb={"10px"}
            >
              <Text padding={"10px"} fontSize={"15px"} textAlign={"center"}>
                {description}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>

      <Flex h={isMobile ? "30px" : "50px"}></Flex>
    </Flex>
  );
};
