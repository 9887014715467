import { useEffect, useState } from "react";
import { theme_color_black, theme_color_white } from "../../const";
import { useSelector } from "react-redux";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import bed from "./../images/bed.svg";
import balcony from "./../images/balcony.svg";
import billiard from "./../images/billiard.svg";
import pipe from "./../images/pipe.svg";
import ps4 from "./../images/ps4.svg";
import ribbon from "./../images/ribbon.svg";

export const Features = ({ featureRef }) => {
  const deviceType = useSelector((state) => state.DeviceType.screenType);
  const languageState = useSelector((state) => state.Language.language);
  const [isMobile, setIsMobile] = useState(deviceType === "mobile");
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    if (deviceType === "wide") {
      setIsMobile(false);
    }
    if (deviceType === "narrow") {
      setIsMobile(false);
    }
    if (deviceType === "mobile") {
      setIsMobile(true);
    }
  }, [deviceType]);

  useEffect(() => {
    if (languageState === "English") {
      import(`../en.json`)
        .then((res) => {
          setTranslations(res.features);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
    if (languageState === "Malay") {
      import(`../bm.json`)
        .then((res) => {
          setTranslations(res.features);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
  }, [languageState]);

  const FeatureBox = ({ title, icon }) => {
    return (
      <Flex flexDir={"column"} justifyContent={"center"}>
        <Flex justify={"center"}>
          <Image src={icon} align={"center"} />
        </Flex>
        <Flex justify={"center"}>
          <Text
            textAlign={"center"}
            align={"center"}
            className="font-link-Benne"
            fontSize={isMobile ? "20px" : "34px"}
            color={theme_color_black}
            pt={"10px"}
            pb={"30px"}
          >
            {title}
          </Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex
      ref={featureRef}
      flexDir={"column"}
      w={"100%"}
      bgColor={theme_color_white}
      p={"50px"}
    >
      <Flex
        justifyContent={isMobile ? "center" : "start"}
        w={"100%"}
        align={"center"}
      >
        <Text
          textAlign={isMobile ? "center" : "start"}
          className="font-link-Anton"
          fontSize={isMobile ? "30px" : "44px"}
          color={"#AF6534"}
        >
          {translations.tagline1}
        </Text>
      </Flex>
      <Flex
        justifyContent={isMobile ? "center" : "start"}
        w={"100%"}
        align={"center"}
      >
        <Text
          textAlign={isMobile ? "center" : "start"}
          className="font-link-Anton"
          fontSize={isMobile ? "45px" : "70px"}
          color={theme_color_black}
        >
          {translations.tagline2}
        </Text>
      </Flex>
      <Flex h="50px"></Flex>
      <Flex
        flexDir={isMobile ? "column" : "row"}
        w={"100%"}
        justifyContent={"space-evenly"}
        align={"center"}
      >
        <Flex
          flexDir={isMobile ? "row" : "column"}
          justifyContent={"space-evenly"}
          width={isMobile ? "100%" : "33%"}
        >
          <Flex width={isMobile ? "50%" : "100%"} justifyContent={"center"}>
            <FeatureBox icon={bed} title={translations.feature1} />
          </Flex>
          <Flex width={isMobile ? "50%" : "100%"} justifyContent={"center"}>
            <FeatureBox icon={billiard} title={translations.feature4} />
          </Flex>
        </Flex>
        <Flex
          flexDir={isMobile ? "row" : "column"}
          justifyContent={"space-evenly"}
          width={isMobile ? "100%" : "33%"}
        >
          <Flex width={isMobile ? "50%" : "100%"} justifyContent={"center"}>
            <FeatureBox icon={ps4} title={translations.feature2} />
          </Flex>
          <Flex width={isMobile ? "50%" : "100%"} justifyContent={"center"}>
            <FeatureBox icon={pipe} title={translations.feature5} />
          </Flex>
        </Flex>
        <Flex
          flexDir={isMobile ? "row" : "column"}
          justifyContent={"space-evenly"}
          width={isMobile ? "100%" : "33%"}
        >
          <Flex width={isMobile ? "50%" : "100%"} justifyContent={"center"}>
            <FeatureBox icon={balcony} title={translations.feature3} />
          </Flex>
          <Flex width={isMobile ? "50%" : "100%"} justifyContent={"center"}>
            <FeatureBox icon={ribbon} title={translations.feature6} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
