import { useEffect, useState } from "react";
import { theme_color_black, theme_color_white } from "../../const";
import { useSelector } from "react-redux";
import { Circle, Fade, Flex, Image, Slide, Text } from "@chakra-ui/react";
import balconyView from "./../images/airbnbImages/balcony2_view.png";
import diningTable from "./../images/airbnbImages/dining_table.png";
import hall from "./../images/airbnbImages/hall.png";
import playingPS4 from "./../images/airbnbImages/ps4.png";
import kitchen from "./../images/airbnbImages/kitchen.png";
import pool from "./../images/airbnbImages/pool.png";
import games from "./../images/airbnbImages/games.png";
import bed from "./../images/airbnbImages/bed.png";
import prevIcon from "./../images/prev.svg";
import nextIcon from "./../images/next.svg";

export const ImageSlide = () => {
  const imageArray = [
    [hall, diningTable, balconyView, playingPS4],
    [kitchen, pool, games, bed],
    [hall, diningTable, balconyView, playingPS4],
    [kitchen, pool, games, bed],
  ];
  const deviceType = useSelector((state) => state.DeviceType.screenType);
  const languageState = useSelector((state) => state.Language.language);
  const [isMobile, setIsMobile] = useState(deviceType === "mobile");
  const [translations, setTranslations] = useState({});
  const [image1, setImage1] = useState(imageArray[0][0]);
  const [image2, setImage2] = useState(imageArray[0][1]);
  const [image3, setImage3] = useState(imageArray[0][2]);
  const [image4, setImage4] = useState(imageArray[0][3]);
  let [slideDotStatus, setSlideDotStatus] = useState(1);
  const slideNo = 4;

  useEffect(() => {
    if (deviceType === "wide") {
      setIsMobile(false);
    }
    if (deviceType === "narrow") {
      setIsMobile(false);
    }
    if (deviceType === "mobile") {
      setIsMobile(true);
    }
  }, [deviceType]);

  useEffect(() => {
    if (languageState === "English") {
      import(`../en.json`)
        .then((res) => {
          setTranslations(res.imageSlide);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
    if (languageState === "Malay") {
      import(`../bm.json`)
        .then((res) => {
          setTranslations(res.imageSlide);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
  }, [languageState]);

  function updateImageSlider(slideDotStatus) {
    setImage1(imageArray[slideDotStatus - 1][0]);
    setImage2(imageArray[slideDotStatus - 1][1]);
    setImage3(imageArray[slideDotStatus - 1][2]);
    setImage4(imageArray[slideDotStatus - 1][3]);
  }

  function next() {
    if (slideDotStatus < slideNo) {
      updateImageSlider(slideDotStatus + 1);
      setSlideDotStatus(slideDotStatus + 1);
    } else {
      updateImageSlider(1);
      setSlideDotStatus(1);
    }
  }

  function prev() {
    if (slideDotStatus > 1) {
      updateImageSlider(slideDotStatus - 1);
      setSlideDotStatus(slideDotStatus - 1);
    } else {
      updateImageSlider(slideNo);
      setSlideDotStatus(slideNo);
    }
  }

  const SliderDots = ({ slideDotStatus }) => {
    const slidesArray = Array.from({ length: slideNo }, (_, index) => index);
    return (
      <Flex w={`${slideNo}%`} flexDir={"row"} justifyContent={"space-around"}>
        {slidesArray.map((index) => {
          let color =
            index + 1 === slideDotStatus ? theme_color_black : "lightgrey";
          return <Circle bgColor={color} w={"10px"} h={"10px"} />;
        })}
      </Flex>
    );
  };

  return (
    <Flex
      flexDir={"column"}
      w={"100%"}
      h={"100%"}
      bgColor={theme_color_white}
      p={"50px"}
      pt={"20px"}
    >
      <Flex
        justifyContent={isMobile ? "center" : "start"}
        w={"100%"}
        align={"center"}
        pb={"20px"}
      >
        <Text
          textAlign={isMobile ? "center" : "start"}
          className="font-link-Anton"
          fontSize={isMobile ? "45px" : "60px"}
          color={theme_color_black}
        >
          {translations.tagline1}
        </Text>
      </Flex>
      <Flex
        flexDir={"row"}
        w={"100%"}
        justifyContent={"space-around"}
        h={"75%"}
        pb={"30px"}
      >
        <Flex alignContent={"center"} w={"48%"}>
          <Image
            src={image1}
            w={"100%"}
            fit={"cover"}
            borderRadius={"15px"}
            boxShadow={"5px 5px 3px rgba(0, 0, 0, 0.2)"}
          />
        </Flex>
        <Flex
          flexDir={"column"}
          w={"48%"}
          h={"100%"}
          alignSelf={"center"}
          justifyContent={"space-around"}
        >
          <Flex
            flexDir={"row"}
            justifyContent={"space-around"}
            w={"100%"}
            h={"47%"}
          >
            <Image
              src={image2}
              w={"48%"}
              fit={"cover"}
              borderRadius={"15px"}
              boxShadow={"5px 5px 3px rgba(0, 0, 0, 0.2)"}
            />
            <Image
              src={image3}
              w={"48%"}
              fit={"cover"}
              borderRadius={"15px"}
              boxShadow={"5px 5px 3px rgba(0, 0, 0, 0.2)"}
            />
          </Flex>
          <Image
            src={image4}
            w={"100%"}
            h={"47%"}
            fit={"cover"}
            borderRadius={"15px"}
            boxShadow={"5px 5px 3px rgba(0, 0, 0, 0.2)"}
          />
        </Flex>
      </Flex>
      <Flex
        flexDir={"row"}
        h={"10%"}
        w={"100%"}
        justifyContent={"space-between"}
      >
        <Flex
          flexDir={"column"}
          justifyContent={"center"}
          cursor={"pointer"}
          onClick={() => {
            prev();
          }}
        >
          <Flex justify={"center"}>
            <Image src={prevIcon} align={"center"} w={"50%"} />
          </Flex>
          <Flex justify={"center"}>
            <Text
              textAlign={"center"}
              align={"center"}
              className="font-link-Antonio"
              fontSize={"25px"}
              color={theme_color_black}
              pt={"5px"}
              pb={"5px"}
            >
              {translations.button1}
            </Text>
          </Flex>
        </Flex>
        <SliderDots slideDotStatus={slideDotStatus} />
        <Flex
          flexDir={"column"}
          justifyContent={"center"}
          cursor={"pointer"}
          onClick={() => {
            next();
          }}
        >
          <Flex justify={"center"}>
            <Image src={nextIcon} align={"center"} w={"70%"} />
          </Flex>
          <Flex justify={"center"}>
            <Text
              textAlign={"center"}
              align={"center"}
              className="font-link-Antonio"
              fontSize={"25px"}
              color={theme_color_black}
              pt={"5px"}
              pb={"5px"}
            >
              {translations.button2}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
